import XtxConfirm from './confirm'
//创建一个虚拟节点
import { createVNode, render } from 'vue'
// 动态创建一个DOM容器
const container = document.createElement('div')
container.setAttribute('class', 'confirm-container')
document.body.appendChild(container)
export default ({ title, text }) => {
  return new Promise((resolve, reject) => {
    // 如果想让then触发，需要调用resolve：点击确认按钮触发
    // 如果想让catch触发，需要调用reject：点击取消按钮触发
    // 点击确认按钮
    const submitCallback = () => {
      // 销毁确认框
      render(null, container)
      resolve()
    }
    // 点击取消按钮
    const cancelCallback = () => {
      // 销毁确认框
      render(null, container)
      // eslint-disable-next-line prefer-promise-reject-errors
      reject('cancel')
    }
    // 把组件渲染到页面中
    const vnode = createVNode(XtxConfirm, { title, text, cancelCallback, submitCallback })
    // 把虚拟节点渲染DOM中
    render(vnode, container)
  })
}

