import Cookies from 'js-cookie'


// token
const TokenKey = 'Admin-Token'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

// other
export function getCookieval(key) {
  return Cookies.get(key)
}

export function setCookieval(key,val) {
  return Cookies.set(key,val)
}

export function removeCookieval(key) {
  return Cookies.remove(key)
}