import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import store from "@/store";
import { getToken } from "@/utils/auth";
import router from "@/router";
import Message from "@/components/tool/message";
import { baseURL } from "@/utils/URL";

const service = axios.create({
    baseURL: baseURL,
    timeout: 10000,
});

service.interceptors.request.use(
    (config: AxiosRequestConfig) => {
        if (store.getters.token) {
            config.headers!["X-GcSoft-Token"] = getToken();
        }
        return config;
    },
    (error) => {
        console.log(error);
        return Promise.reject(error);
    }
);

service.interceptors.response.use(
    (response: AxiosResponse) => {
        const res = response.data;
        if (res.code != 200) {
            switch (res.code) {
                case 500:
                    Message({ text: res.des || "错误" });
                    break;
                case 600:
                    Message({ text: res.des || "用户登录过期，请重新登录" });
                    store.dispatch("logout");
                    router.push("/login");
                    break;
				case 404:
					Message({ text: res.des || "手机号已存在" });
                    break;
                default:
                    Message({ text: res.des || "错误" });
            }
            return Promise.reject(new Error(res.des || "错误"));
        } else {
            return res;
        }
    },
    (error) => {
        console.log("错误" + error);
        Message({ text: error || "错误" });
        return Promise.reject(error);
    }
);

export default service;
