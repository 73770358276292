import { createStore } from "vuex";
import {
    getToken,
    setToken,
    removeToken,
    getCookieval,
    setCookieval,
    removeCookieval,
} from "@/utils/auth";
import { login } from "@/api/user";

export default createStore({
    state: {
        token: getToken(),
        name: getCookieval("name"),
    },
    getters: {
        token: (state) => state.token,
        name: (state) => state.name,
    },
    mutations: {
        // 登录token
        SET_TOKEN: (state, token) => {
            state.token = token;
        },
        // 用户信息名称
        SET_NAME: (state, name) => {
            state.name = name;
        },
    },
    actions: {
        // 登录
        login({ commit }, userInfo) {
            const { userName, password } = userInfo;
            return new Promise((resolve, reject) => {
                login({ userName: userName, password: password })
                    .then((response) => {
                        commit("SET_TOKEN", response["obj"]["password"]);
                        setToken(response["obj"]["password"]);
                        commit("SET_NAME", response["obj"]["userName"]);
                        setCookieval("name", response["obj"]["userName"]);
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
    },
    modules: {},
});
