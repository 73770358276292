import router from '@/router'
import store from '@/store'
import { getToken } from '@/utils/auth'
import Message from '@/components/tool/message'
const whiteList = ['/login','/404']

router.beforeEach(async (to, from, next) => {
  next()
  // const hasToken = getToken()
  // if (hasToken) {
  //   if (to.path === '/login') {
  //     next({ path: '/' })
  //   } else {
  //     var timestamp = Date.parse(new Date().toString());
  //     var tokentime = store.getters.tokentime
  //     if (timestamp > tokentime) {
  //       store.dispatch("logout");
  //       Message({ text: '登录已过期，请重新登录' })
  //       next({ path: '/login' })
  //     } else {
  //       next()
  //     }
  //   }
  // } else {
  //   if (whiteList.indexOf(to.path) !== -1) {
  //     next()
  //   } else {
  //     next(`/login`)
  //   }
  // }
})

router.afterEach(() => {
  // 完成跳转后执行
})
