import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/index",
  },
  {
    path: "/layout",
    name: "layout",
    component: () => import("@/components/layout/index.vue"),
    children: [
      {
        path: "/index",
        name: "index",
        meta: {
          title: '首页',
        },
        component: () => import("@/views/index/index.vue")
      },
      {
        path: "/about",
        name: "about",
        meta: {
          title: '关于我们',
        },
        component: () => import("@/views/about/index.vue")
      },
      {
        path: "/news",
        name: "news",
        meta: {
          title: '新闻动态',
        },
        component: () => import("@/views/news/index.vue")
      },
      {
        path: "/news/details",
        name: "news-details",
        meta: {
          title: '新闻动态',
        },
        component: () => import("@/views/news/details.vue")
      },
      {
        path: "/education",
        name: "education",
        meta: {
          title: '教育体系',
        },
        component: () => import("@/views/education/index.vue")
      },
      {
        path: "/course",
        name: "course",
        meta: {
          title: '课程产品',
        },
        component: () => import("@/views/course/index.vue")
      },
      {
        path: "/join",
        name: "join",
        meta: {
          title: '品牌加盟',
        },
        component: () => import("@/views/join/index.vue")
      },
      {
        path: "/operate",
        name: "operate",
        meta: {
          title: '运营服务',
        },
        component: () => import("@/views/operate/index.vue")
      },
      {
        path: "/witness",
        name: "witness",
        meta: {
          title: '品牌见证',
        },
        component: () => import("@/views/witness/index.vue")
      },
      {
        path: "/contact",
        name: "contact",
        meta: {
          title: '联系我们',
        },
        component: () => import("@/views/contact/index.vue")
      },
      {
        path: "/chat",
        name: "chat",
        meta: {
          title: '申请合作',
        },
        component: () => import("@/views/chat/index.vue")
      },
    ]
  },
  {
    path: "/:pathMatch(.*)",
    redirect: "/404",
  },
  {
    path: "/404",
    name: "404",
    meta: {
      title: '404',
    },
    component: () => import("@/views/error/404.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
