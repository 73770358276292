export default {
    data() {
        return {
            queryData: {},
        };
    },
    mounted() {
        this.queryData = this.$route.query;
        if (this.queryData.draw) {
            setTimeout(() => {
                this.getlayoutdata(this.queryData.draw);
            }, 800);
        }
    },
    methods: {
        todraw(url, idname) {
            this.onURL(url);
            setTimeout(() => {
                this.$refs.refrouter.getlayoutdata(idname);
            }, 800);
        },
        getlayoutdata(selector) {
            console.log(selector, 222);
            this.$el.querySelector(selector).scrollIntoView({
                behavior: "smooth",
                block: "start",
            });
        },
        onURL(url, query, sc) {
            this.$router.push({
                path: url,
                query,
            });
            if (sc == 1) {
                document.documentElement.scrollTop = 0;
            }
        },
        nullstring(data) {
            for (let x in data) {
                if (data[x] === null) {
                    data[x] = "";
                } else {
                    if (Array.isArray(data[x])) {
                        data[x] = data[x].map((z) => {
                            return this.nullstring(z);
                        });
                    }
                    if (typeof data[x] === "object") {
                        data[x] = this.nullstring(data[x]);
                    }
                }
            }
            return data;
        },
    },
};
