import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import '@/utils/permission'
import store from "./store";
import conmon from "@/mixins/common";
import 'wow.js/css/libs/animate.css'
import '@/styles/index.scss'
import '@/assets/js/code.js'

// 注入
const app = createApp(App)
app.use(router)
app.use(store)
app.mixin(conmon)

// 自定义message
import Msg from '@/components/tool/message'
app.config.globalProperties.$Msg = Msg;

// 自定义Confirm
import Confirm from '@/components/tool/confirm'
app.config.globalProperties.$Confirm = Confirm;


app.mount("#app");
