<template>
  <div class="confirm" :class='{ fade: fade }'>
    <div class="wrapper" :class='{ fade: fade }'>
      <div class="header">{{title}}</div>
      <div class="body">
        <span>{{text}}</span>
      </div>
      <div class="footer">
        <div class="stepbtn" @click='cancelCallback'>取消</div>
        <div class="stepbtn" @click='submitCallback'>确认</div>
      </div>
    </div>
  </div>
</template>
<script>
import { onMounted, ref, onUpdated } from "vue";

export default {
  name: "Confirm",
  props: {
    title: {
      type: String,
      default: "",
    },
    text: {
      type: String,
      default: "",
    },
    cancelCallback: {
      type: Function,
      required: true,
    },
    submitCallback: {
      type: Function,
      required: true,
    },
  },
  setup() {
    const fade = ref(false);
    onUpdated(() => {
      console.log("模板依赖的数据发生变化时触发");
    });
    onMounted(() => {
      setTimeout(() => {
        fade.value = true;
      }, 0);
    });
    return { fade };
  },
};
</script>
<style scoped lang="scss">
.confirm {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 8888;
  background: rgba(0, 0, 0, 0);
  &.fade {
    transition: all 0.4s;
    background: rgba(0, 0, 0, 0.8);
  }
  .wrapper {
    width: 480px;
    height: 258px;
    background-image: url("@/assets/image/showalert.png");
    background-size: 100%;
    color: #05dde5;
    border-radius: 4px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -60%);
    opacity: 0;
    &.fade {
      transition: all 0.4s;
      transform: translate(-50%, -50%);
      opacity: 1;
    }
    .header {
      height: 42px;
      line-height: 42px;
      font-size: 18px;
      text-align: center;
    }
    .body {
      padding: 58px 40px;
      text-align: center;
      font-size: 16px;
    }
    .footer {
      padding: 0 50px;
      display: flex;
      justify-content: space-around;
      align-items: center;

      .stepbtn {
        cursor: pointer;
        width: 120px;
        height: 40px;
        border: 1px solid #199bff;
        line-height: 40px;
        text-align: center;
        color: #05dde5;
      }
    }
  }
}
</style>

